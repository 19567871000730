@font-face {
    font-family: "ABC Corporate Extra Light";
    src: url("../fonts/abc-corporate-extra-light.ttf") format("truetype");
    font-style: normal;
  }
  
  @font-face {
    font-family: "AB Chanel";
    src: url("../fonts/abchanel.ttf") format("truetype");
    font-style: normal;
  }
  
  html,
  body {
    height: 100%;
    background-color: #ffffff !important; /* or your desired background color */
    margin: 0;
    padding: 0;
    /* line-height: 1.25rem !important; */
    color: #212529;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b {
    font-family: "AB Chanel", sans-serif;
    font-weight: 500 !important;
  }
  
  p,
  span,
  ul,
  li,
  ol,
  div {
    font-size: 14px;
  }
  
  .start-campaign-input,
  .search-input {
    font-family: "ABC Corporate Extra Light", sans-serif;
    font-size: 12px !important;
  }
  
  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-family: inherit !important;
    font-size: inherit !important;
  }
  
  .black-button {
    border-radius: 4px;
    background-color: black !important;
    color: white;
    font-size: 14px;
    padding: 8px 16px;
    transition: opacity 0.2s;
  }
  
  .black-button:hover {
    opacity: 0.8;
  }
  
  .gray-button {
    border-radius: 4px;
    background-color: #efeff0 !important;
    color: #62646a;
    font-size: 14px;
    padding: 8px 16px;
    transition: opacity 0.2s;
  }
  
  .gray-button:hover {
    background-color: #e5e5e5;
  }
  
  input {
    outline: none;
  }
  input:focus {
    outline: none;
  }
  
  .shortcut-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  
  .shortcut-dropdown-item {
    display: block;
    width: 100%;
    min-width: 200px;
    padding: 0.5rem;
    text-align: center;
    white-space: nowrap;
    border-bottom: 1px solid #e5e5e5;
    color: #62646a;
    font-size: 14px;
  }
  
  .shortcut-dropdown-item:last-child {
    border: none;
  }
  
  .shortcut-dropdown-item:hover {
    background-color: #efeff0;
  }
  
  .profile-dropdown-menu {
    position: absolute;
    top: 44px;
    right: 5%;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    z-index: 1050;
    width: 250px;
    padding-top: 1rem;
    padding-bottom: 0.3rem;
    border: 1px solid #e5e5e5;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .profile-dropdown-item {
    display: flex;
    width: 100%;
    padding: 0.5rem 1rem;
    text-align: left;
    color: #62646a;
    font-size: 14px;
    align-items: center;
  }
  
  .profile-dropdown-item:last-child {
    border: none;
  }
  
  .profile-dropdown-item:hover {
    background-color: #efeff0;
  }
  
  /* For slider */
  .slick-prev {
    left: -20px !important; /* Adjust to position the arrow */
  }
  
  .slick-next {
    right: -20px !important; /* Adjust to position the arrow */
  }
  
  .slick-prev,
  .slick-next {
    display: flex !important;
    padding: 1rem !important;
    border: 1px solid #62646a !important;
    border-radius: 50% !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: white !important;
    z-index: 1000;
  }
  
  .slick-prev:before {
    content: "\f104" !important; /* FontAwesome right arrow */
  }
  
  .slick-next:before {
    content: "\f105" !important; /* FontAwesome right arrow */
  }
  
  .slick-prev:before,
  .slick-next:before {
    font-family: "FontAwesome" !important;
    font-size: 24px;
    color: #000 !important; /* Change the color */
  }
  
  .slick-slide {
    padding: 0 8px !important; /* Adjust the value as needed */
  }
  
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
  
  .enhanced-textarea::-webkit-scrollbar {
    width: 8px;
    border-radius: 0%;
  }
  
  .enhanced-textarea::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
    border-radius: 0%;
  }
  
  .enhanced-textarea::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
  
  .box-scrollbar {
    scroll-behavior: smooth;
  }
  
  @media (max-width: 768px) {
    .box-scrollbar::-webkit-scrollbar {
      width: 0px !important;
      border-radius: 0% !important;
    }
  
    .box-scrollbar::-webkit-scrollbar-thumb {
      background: #ffffff !important;
      border-radius: 0px !important;
      border-radius: 0% !important;
    }
  
    .box-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #ffffff !important;
    }
  
    .box-scrollbar::-webkit-scrollbar {
      display: none !important; /* Hide scrollbar in WebKit browsers */
    }
  }
  
  input,
  textarea,
  select {
    font-size: 14px !important;
  }
  
  /* specifically for start campaign page only */
  .pac-container.pac-logo {
    position: absolute !important;
    bottom: 90px !important;
    top: auto !important;
  }
  
  .form-label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .form-input,
  .form-select,
  .form-textarea {
    padding: 10px 12px;
    min-height: 38px;
    width: 100%;
    border-radius: 4px;
    font-size: 14px !important;
    outline: none;
  }
  
  .form-input:focus,
  .form-select:focus,
  .form-textarea:focus {
    outline: none;
  }
  
  .form-input:disabled {
    border: none;
    background: #f5f5f5;
  }
  
  .form-error-message {
    color: #ef4444;
    font-size: 12px;
  }
  
  select {
    /* Remove default arrow in some browsers */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  
    background-color: white;
  
    /* Add the custom arrow */
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 24 24' height='1em' width='1em' aria-hidden='true'%3e%3cpath fill-rule='evenodd' d='M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z' clip-rule='evenodd'%3e%3c/path%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 9.2px center;
    background-size: 12px;
  }
  
  /* Hide arrow in IE10 and IE11 */
  select::-ms-expand {
    display: none;
  }
  
  input[type="checkbox"] {
    accent-color: black;
  }
  
  .custom-legend {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
    text-align: left;
  }
  
  .legend-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .chain {
    width: 6rem;
    height: 3rem;
    border-radius: 20px;
  }
  .chain-stick {
    width: 4rem;
    height: 0.8rem;
    margin: 0px -6px;
    border: 2px solid white;
    border-radius: 32px;
    position: relative;
    top: -20px;
  }
  
  /* video post list start */
  
  .video-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
  
  /* Reduce video height on mobile and tablet screens */
  @media (max-width: 768px) {
    .video-container {
      height: 85vh; /* Adjust height for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .video-container {
      height: 75vh; /* Further adjust height for smaller screens */
    }
  }
  
  /* video post list end */
  
  @media only screen and (max-width: 768px) {
    table td,
    table th {
      font-size: 9px;
    }
  }
  
  .custom-modal {
    margin: 0 !important;
  }
  
  
  .table-container {
    margin: 20px;
  }
  
  .pagination-info {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .table-responsive {
    overflow-x: auto; /* Makes the table scrollable on mobile devices */
  }
  
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f8f8f8;
  }
  
  .styled-table th,
  .styled-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    font-size: 14px;
  }
  
  .styled-table th {
    background-color: black;
    color: #fff;
  }
  
  .styled-table th:not(:first-child) {
    width: max-content;
    min-width: 120px;
  }
  
  .styled-table tr:nth-of-type(even) {
    background-color: #f2f2f2;
  }
  
  .styled-table tr:hover {
    background-color: #e1e1e1;
  }
  
  .column-filter {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
    font-size: 14px;
    outline: none;
  }
  .column-filter:focus {
    outline: none;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-controls button {
      font-size: 14px;
    padding: 4px 10px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    cursor: pointer;
  }
  
  .pagination-controls button.active {
    background-color: black;
    color: #fff;
  }
  
  .pagination-controls button:disabled {
    background-color: #ccc;
  }
  
  .pagination-controls button:hover:not(.active) {
    background-color: #ddd;
  }
  
  .view-icon {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 14px;
  }