pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-family: inherit !important;
  line-height: 1.2;
}

input {
  font-size: 16px !important;
}

/* typewrite effect */
.typewriter {
  display: inline-block;
}

.typewriter.animate {
  animation: typing 2s steps(40, end) step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.startScreen {
  background-image: url("https://melo-story.s3.eu-north-1.amazonaws.com/Dodie+-+stories.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}

body {
  display: flex;
  flex-direction: column;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

#msg {
  height: calc(90vh - 200px); /* Adjust as needed */
}

textarea {
  resize: none;
}
